#services {
  height: 100%;
}
#services-container {
  position: relative;
  display: grid;
  grid-template-columns: 25% auto;
  min-height: 100%;
  img {
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: min(100%, 100vh);
    object-fit: cover;
    z-index: -1;
  }
}

#services-content-container {
  margin: 0 auto 0 50px;
  background-color: white;
  .service-card-content {
    padding: 0 20px;
    text-align: left;
    h2 {
      margin-bottom: 0;
    }
    ul {
      margin-top: 5px;
    }
  }
}
