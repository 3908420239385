#customers {
  width: 100%;
}

#customers-bar {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1400px;
  height: 80px;
  margin: 50px auto;
  box-shadow: 0 0 40px 0px#362a97;
  overflow: hidden;
}

#customers-photo-banner {
  position: absolute;
  display: flex;
  height: 40px;
  padding: 20px;
  animation: bannermove 30s linear infinite;
  img {
    width: auto;
    height: 100%;
    margin: 0 10px;
  }
}

@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
