#home-hero {
  position: relative;
  height: calc(100vh - 68px);
  // background-color: #03001a;
  background-color: #05002d;
  overflow: hidden;
}

#home-hero-text {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;
  width: 50%;
  height: 100%;
  color: white;
  background: transparent;
  font-family: 'Abel', sans-serif;
  font-size: 1.25rem;
  transition: backdrop-filter .25s ease;
  z-index: 2;
  h1:first-child {
    animation: slide-up;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }
  h1:nth-child(2) {
    animation: slide-up;
    animation-delay: 1s;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    margin-bottom: 0;
    opacity: 0;
  }
  h1:nth-child(3) {
    animation: zoom-in;
    animation-delay: 1.5s;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    margin-top: 0;
    opacity: 0;
  }
}
#home-hero-canvas-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media screen and (max-width: 600px) {
  #home-hero-text {
    position: absolute;
    width: 100%;
    backdrop-filter: blur(5px);
  }
  #home-hero-canvas-container {
    width: 100%;
  }
}
#home-hero-action-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3;
}
#home-hero-action {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  margin: 20px auto;
  color: white;
  background-color: #362a97;
  border: none;
  border-radius: 10%;
  font-family: 'Abel', sans-serif;
  font-size: 1.5rem;
  transition: font-size .1s ease;
  cursor: pointer;
  svg {
    margin: 0 auto;
  }
  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #362a97;
    border-radius: inherit;
  }
  &:not(:hover) {
    &::after {
      animation: ripple 1s ease infinite;
    }
  }
  &:hover {
    font-size: 1.7rem;
  }
}

.space-text {
  font-family: 'Play', sans-serif;
  font-size: 6rem;

}

@keyframes slide-up {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(.1);
    opacity: 0;
  } to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ripple {
  0% {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  } 50% {
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
  } 100% {
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    opacity: 0;
  }
}