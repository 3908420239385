.site-divider-container {
  width: calc(100% - 40px);
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}
.site-divider {
  position: relative;
  border: 1px solid black;
  &:first-child {
    margin-bottom: 5px;
  }
}