#main-menu {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 14px 10px;
  background-color: white;
  box-shadow: 0 0 4px 0 #888;
  z-index: 500;
}

#site-icon {
  height: 48px;
  img {
    height: 100%;
  }
}
#main-menu-toggle {
  cursor: pointer;
  rect {
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 0.25s ease, opacity 0.25s ease;
  }
  &.toggled {
    rect:nth-of-type(1) {
      transform: translateY(12px) rotate(45deg);
    }
    rect:nth-of-type(2) {
      transform: rotate(45deg);
      opacity: 0;
    }
    rect:nth-of-type(3) {
      transform: translateY(-12px) rotate(-45deg);
    }
  }
}
#main-menu-routes {
  ul {
    display: flex;
    list-style: none;
    height: 100%;
    margin: 0;
    padding-inline-start: 0;
    li {
      display: flex;
      align-items: stretch;
      a {
        display: flex;
        place-items: center;
        padding: 0 5px;
        text-decoration: none;
      }
    }
  }
}

.mobile {
  position: absolute;
  width: 50%;
  z-index: 500;
  transition: right 0.25s ease;
  &:not(.toggled) {
    right: -50%;
  }
  &.toggled {
    right: 0;
  }
  ul {
    flex-direction: column;
    li {
      a {
        width: 100%;
        padding: 10px 20px !important;
        border: 1px solid #eee;
        background: white;
      }
    }
  }
}
