#home-about {
  display: grid;
  grid-template-rows: 1fr auto;
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
  h1 {
    padding: 20px;
    font-family: 'Abel', sans-serif;
  }
}
#home-about-services {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  gap: 5px;
  // list-style-type: disc;
  .home-about-service {
    max-width: 400px;
    padding: 10px;
    margin: 0 auto;
    text-align: left;
    p {
      margin: 0;
      font-family: "Roboto", sans-serif;
      font-size: 1.4rem;
      font-weight: 300;
    }
  }
}