.site-blocker {
  position: fixed;
  z-index: 499;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0,0,0,.7);
  opacity: 0;
  transition: opacity .1s ease;
}