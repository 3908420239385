#approach-content {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
  img {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
}
