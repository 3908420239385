footer {
  display: flex;
  place-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #EEE;
  color: #AAA;
  font-size: .8rem;
}

#footer-content {
  p {
    margin: 0;
  }
}